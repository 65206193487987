import { FC, LegacyRef, MouseEventHandler, useEffect, useRef, useState } from 'react';
import { useQuery } from '../../hooks';
import { ArrowCarousel, ArrowForward, Battery, Close, HandsOk, WellbeingHand } from '../../assets/svg';
import styles from './styles.module.css';
import { CircularProgress } from '@mui/material';
import { t } from 'i18next';
import { Button } from '../../components/Button';
import axios from 'axios';
import Navbar from '../../components/Navbar';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ExportResults from './ExportResults';
import { Trans, useTranslation } from 'react-i18next';
import Footer from '../../components/Footer';
import ScannerFace from '../../assets/images/scanner_face.png';
import { useHistory } from 'react-router-dom';
import { OkIcon } from '../../assets/svg/OkIcon';

const HowItWorks = ({
    handleBack,
  }: {
    handleBack: MouseEventHandler<SVGSVGElement>;
  }) => (
    <div className={`${styles.contentHowItWorks}`}>
        <div className={styles.goBack}>
            <ArrowCarousel onClick={handleBack} />
        </div>
        <h1 className={styles.title}>{t('profile.step7.title')}</h1>
        <div className={`${styles.contentHowItWorksText}`}>
            <div style={{ width: '100%' }}>
                <p className={styles.paragraph}>{ <Trans i18nKey="profile.step7.p1" />}</p>
                <br />
                <p className={styles.paragraph}>{ <Trans i18nKey="profile.step7.p2" />}</p>
                <br />
                <p className={styles.paragraph}>{ <Trans i18nKey="profile.step7.p3" />}</p>
            </div>
            <img src={ScannerFace} alt="Scanner Face" className={styles.scannerFaceInfo} />
        </div>
    </div>
);

export const Results: FC = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { i18n } = useTranslation();
    const [isHowItWorks, setIsHowItWorks] = useState<boolean>(false);
    const [resultsData, setResultsData] = useState<any>(undefined);
    const [errorData, setErrorData] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [resultsSent, setResultsSent] = useState<boolean>(false);
    const [isDetailedResults, setIsDetailedResults] = useState<boolean>(false);
    const query = useQuery();
    const summaryRef = useRef<HTMLElement>();
    const history = useHistory();
    const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

    const displayDetailedResults = () => {
        window.scrollTo(0, 0);
        setIsDetailedResults(!isDetailedResults);
    };

    const generatePdf = async () => {
        setIsGeneratingPdf(true);

        try {
            const pdf = new jsPDF('p', 'mm', 'a4');

            // Function to convert HTML to canvas and add to PDF
            const addPageToPdf = async (ref: any) => {
                if (!ref.current) return;

                const canvas = await html2canvas(ref.current, {
                    scale: 1, // Adjust the scale as needed
                    useCORS: true,
                });

                const imgData = canvas.toDataURL('image/jpeg', 0.75); // Use JPEG and reduce quality
                pdf.addImage(imgData, 'JPEG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
            };

            await addPageToPdf(summaryRef);

            // if (localStorage.getItem('pharmacy')) {
            //     pdf.addPage();
            //     await addPageToPdf(detailedRef);
            // }

            const pdfBlob = pdf.output('blob');

            // Send the PDF to the backend
            const formData = new FormData();
            formData.append('pdf', pdfBlob, 'document.pdf');
            formData.append('sessionId', resultsData.SESSION_ID);

            try {
                await axios.post('/api/invitation', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            } catch (error) {
                console.error('Error sending PDF', error);
            }
        } catch (error) {
            console.error('Error generating PDF:', error);
        } finally {
            setIsGeneratingPdf(false);
            setResultsSent(true);
        }
    };

    const handleExport = async () => {
        if (isGeneratingPdf) {
            return;
        }

        localStorage.setItem('resultsSent', 'true');

        await generatePdf();
    };

    const handleProceed = () => {
        if (localStorage.getItem('source') === 'yoboo_app') {
            localStorage.removeItem('source');
            if (window.location.href.includes('dev')) {
                window.location.href = 'https://yoboo-dev.app.link/';
            } else {
                window.location.href = 'https://yoboo.app.link/';
            }
        } else if (localStorage.getItem('pharmacyId') === 'Calysta') {
            window.location.href = 'https://yoboo.typeform.com/to/FsD3kOxG';
        } else {
            history.push('/proceed');
        }
    };

    const handleHowItWorks = () => {
        window.scrollTo(0, 0);
        setIsHowItWorks(!isHowItWorks);
    };

    useEffect(() => {
        const resultsSent = localStorage.getItem('resultsSent');

        if (resultsSent) {
            setResultsSent(true);
        }

        if (resultsData || errorData) {
            return;
        }

        const results = query.get('results');
        const error = query.get('error');

        try {
            if (error || (error !== '' && error !== null)) {
                setErrorData(error);
                setLoading(false);

                return;
            }

            if (!results || results === '') {
                setErrorData(true);
                setLoading(false);

                return;
            }

            const resultsBuffer = Buffer.from(results, 'base64');
            const resultsObj = JSON.parse(resultsBuffer.toString());

            console.log('Nuralogix response', resultsObj);

            if (
                'MENTAL_SCORE' in resultsObj
                && 'PHYSICAL_SCORE' in resultsObj
                && 'PHYSIO_SCORE' in resultsObj
                && 'VITAL_SCORE' in resultsObj
                && typeof resultsObj.MENTAL_SCORE === 'number'
                && typeof resultsObj.PHYSICAL_SCORE === 'number'
                && typeof resultsObj.PHYSIO_SCORE === 'number'
                && typeof resultsObj.VITAL_SCORE === 'number'
            ) {
                setResultsData(resultsObj);
            } else {
                setErrorData(true);
            }
        } catch (e) {
            setErrorData(e);
        }

        setLoading(false);
    }, [errorData, query, resultsData]);

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
            </div>
        );
    }

    if (errorData) {
        return (
            <>
                <div style={{
                    boxShadow: 'none',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'transparent',
                    gap: '1.5rem',
                    padding: 0,
                }}>
                    <Navbar isResults={false} />
                    <div className={styles.containerError}>
                        <div className={styles.content}>
                            <div className={styles.results_error_box}>
                                <div className={styles.results_error_header}>
                                    <p className={styles.results_error_title}>{t('results.error.title')}</p>
                                    <Close width='20px' onClick={() => { window.location.href = '/'; }} />
                                </div>
                                <div className={styles.results_error_body}>
                                    <p className={styles.results_error_text}>{t('results.error.description')}</p>
                                    <Button text={t('results.error.button')} onClick={() => { window.location.href = '/'; }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.footerErrorContainer}>
                    <Footer />
                </div>
            </>
        );
    }

    if (!resultsSent) {
        return (
            <div className={styles.results_done_screen}>
                <div style={{ height: 0, overflow: 'auto', width: '100%' }}>
                    <ExportResults isDetailedResults={false} resultsData={resultsData} exportRef={summaryRef as LegacyRef<HTMLDivElement> | undefined} />
                </div>
                <Navbar isResults={false} backgroundColor='#27A577'/>
                <div className={styles.allContentContainer}>
                    <div className={styles.allContent}>
                        <div className={styles.imageContainer}>
                            <HandsOk />
                        </div>
                        <div className={styles.container}>
                            <div className={styles.textContainerIntro}>
                                <p className={styles.titleIntro}><Trans i18nKey="results.results_done.title" /></p>
                                <p className={styles.textIntro}>{t('results.results_done.description')}</p>
                            </div>
                            <Button buttonType='big' className={styles.nextButtonIntro} id="metYoboo" text={isGeneratingPdf ? `${t('results.results_done.button')}...` : t('results.results_done.button')} onClick={handleExport} style={{ backgroundColor: '#EFEEE1', color: '#191717' }} />
                        </div>
                    </div>
                </div>
                <div>
                    <Footer backgroundColor='#27A577' color='#F5F5EF' />
                </div>
            </div>
        );
    }

    return (
        <>
            <Navbar isResults={true} />
            {isHowItWorks ?
                <div className={styles.howItWorks}>
                    <HowItWorks handleBack={handleHowItWorks} />
                </div>
                :
                <div className={styles.results}>
                    <div className={styles.top_container}>
                        <div className={styles.results_header}>
                            {isDetailedResults && <div className={styles.goBack}>
                                <ArrowCarousel onClick={displayDetailedResults} />
                            </div>}
                            <h2 className={styles.title}>{isDetailedResults ? t('results.header.measurements.title') : t('results.header.energy.title')}</h2>
                            <p className={`${styles.paragraph} ${styles.results_header_size}`}><Trans i18nKey={isDetailedResults ? t('results.header.measurements.description') : t('results.header.energy.description')} /></p>
                        </div>
                        {isDetailedResults ?
                            <div className={`${styles.results_info_detailed}`}>
                                <div className={`${styles.results_info_detailed_item}`}>
                                    <div className={styles.results_info_item}>
                                        <div className={styles.results_info_item_details}>
                                            <h3 className={styles.title_item}>{t('results.measurements.title1')}</h3>
                                        </div>
                                    </div>
                                    <table style={{ borderSpacing: '0px 7px' }}>
                                        <tbody>
                                            <tr>
                                                <th className={styles.table_title} style={{ width: '144px' }}>{t('results.measurementsTableHeader1.value')}</th>
                                                <th className={styles.table_title}>{t('results.measurementsTableHeader1.name')}</th>
                                            </tr>
                                            <tr>
                                                <td className={`${styles.table_item} ${styles.textLeft}`}>{resultsData.HR_BPM.toFixed(0)}</td>
                                                <td className={styles.table_item}>{t('results.measurements.HR_BPM')}</td>
                                            </tr>
                                            <tr>
                                                <td className={`${styles.table_item} ${styles.textLeft}`}>{resultsData.IHB_COUNT.toFixed(0)}</td>
                                                <td className={styles.table_item}>{t('results.measurements.IHB_COUNT')}</td>
                                            </tr>
                                            <tr>
                                                <td className={`${styles.table_item} ${styles.textLeft}`}>{resultsData.BR_BPM.toFixed(0)}</td>
                                                <td className={styles.table_item}>{t('results.measurements.BR_BPM_STRICT')}</td>
                                            </tr>
                                            <tr>
                                                <td className={`${styles.table_item} ${styles.textLeft}`}>{resultsData.BP_SYSTOLIC.toFixed(0)}</td>
                                                <td className={styles.table_item}>{t('results.measurements.BP_SYSTOLIC')}</td>
                                            </tr>
                                            <tr>
                                                <td className={`${styles.table_item} ${styles.textLeft}`}>{resultsData.BP_DIASTOLIC.toFixed(0)}</td>
                                                <td className={styles.table_item}>{t('results.measurements.BP_DIASTOLIC')}</td>
                                            </tr>
                                            <tr>
                                                <td className={`${styles.table_item} ${styles.textLeft}`}>{resultsData.HRV_SDNN.toFixed(0)}</td>
                                                <td className={styles.table_item}>{t('results.measurements.HRV_SDNN')}</td>
                                            </tr>
                                            <tr>
                                                <td className={`${styles.table_item} ${styles.textLeft}`}>{resultsData.BP_RPP.toFixed(0)}</td>
                                                <td className={styles.table_item}>{t('results.measurements.BP_RPP')}</td>
                                            </tr>
                                            <tr>
                                                <td className={`${styles.table_item} ${styles.textLeft}`}>{resultsData.BP_TAU.toFixed(0)}</td>
                                                <td className={styles.table_item}>{t('results.measurements.BP_TAU')}</td>
                                            </tr>
                                            <tr>
                                                <td className={`${styles.table_item} ${styles.textLeft}`}>{resultsData.AGE.toFixed(0)}</td>
                                                <td className={styles.table_item}>{t('results.measurements.AGE')}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className={`${styles.results_info_detailed_item}`}>
                                    <div className={styles.results_info_item}>
                                        <div className={styles.results_info_item_details}>
                                            <h3 className={styles.title_item}>{t('results.measurements.title2')}</h3>
                                        </div>
                                    </div>
                                    <table style={{ borderSpacing: '0px 7px' }}>
                                        <tbody>
                                            <tr>
                                                <th className={styles.table_title} style={{ width: '144px' }}>{t('results.measurementsTableHeader2.value')}</th>
                                                <th className={styles.table_title}>{t('results.measurementsTableHeader2.name')}</th>
                                            </tr>
                                            <tr>
                                                <td className={`${styles.table_item} ${styles.textLeft}`}>{resultsData.HBA1C_RISK_PROB.toFixed(0)}%</td>
                                                <td className={styles.table_item}>{t('results.measurements.HBA1C_RISK_PROB')}</td>
                                            </tr>
                                            <tr>
                                                <td className={`${styles.table_item} ${styles.textLeft}`}>{resultsData.DBT_RISK_PROB.toFixed(0)}%</td>
                                                <td className={styles.table_item}>{t('results.measurements.DBT_RISK_PROB')}</td>
                                            </tr>
                                            <tr>
                                                <td className={`${styles.table_item} ${styles.textLeft}`}>{resultsData.HDLTC_RISK_PROB.toFixed(0)}%</td>
                                                <td className={styles.table_item}>{t('results.measurements.HDLTC_RISK_PROB')}</td>
                                            </tr>
                                            <tr>
                                                <td className={`${styles.table_item} ${styles.textLeft}`}>{resultsData.HPT_RISK_PROB.toFixed(0)}%</td>
                                                <td className={styles.table_item}>{t('results.measurements.HPT_RISK_PROB')}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            :
                            <div className={styles.results_info}>
                                <div className={styles.results_info_item}>
                                    <div className={styles.results_info_item_details}>
                                        <h3 className={styles.title_item}>{t('results.metabolic.title')}</h3>
                                    </div>
                                    <div className={styles.results_info_item_battery}>
                                        <Battery score={Math.round((resultsData.PHYSIO_SCORE + resultsData.VITAL_SCORE) / 2) * 2}  fill="#18A81D" />
                                        <p className={styles.paragraph_small}>{t('results.metabolic.description')}</p>
                                    </div>
                                </div>
                                <div className={styles.results_info_item}>
                                    <div className={styles.results_info_item_details}>
                                        <h3 className={styles.title_item}>{t('results.physical.title')}</h3>
                                    </div>
                                    <div className={styles.results_info_item_battery}>
                                        <Battery score={Math.round(resultsData.PHYSICAL_SCORE) * 2} fill="#D46114" />
                                        <p className={styles.paragraph_small}>{t('results.physical.description')}</p>
                                    </div>
                                </div>
                                <div className={styles.results_info_item}>
                                    <div className={styles.results_info_item_details}>
                                        <h3 className={styles.title_item}>{t('results.mental.title')}</h3>
                                    </div>
                                    <div className={styles.results_info_item_battery}>
                                        <Battery score={Math.round(resultsData.MENTAL_SCORE) * 2} fill="#2193C5" />
                                        <p className={styles.paragraph_small}>{t('results.mental.description')}</p>
                                    </div>
                                </div>
                            </div>
                        }
                        {!isDetailedResults && <div className={styles.results_buttons}>
                            <Button className={styles.button_container} text={t('results.preFooter.buttonHow')} onClick={handleHowItWorks} />
                            {localStorage.getItem('pharmacyId') && <Button className={styles.button_container} text={t('results.preFooter.buttonNumbers')} onClick={displayDetailedResults} />}
                        </div>}
                    </div>
                    <div className={styles.results_footer}>
                        <div className={styles.results_footer_container}>
                            <div className={`${styles.paragraphs_container}`}>
                                <h2 className={styles.bottom_title} style={{ color: '#FBFBF9' }}>{t('results.footer.title')}</h2>
                                <p className={styles.paragraph} style={{ color: '#F1F1E9' }}>{t('results.footer.description')}</p>
                                <Button className={styles.button_container} text={t('results.footer.button')} onClick={handleProceed} icon={<ArrowForward fill="#191717" width='20' />} />
                                <div className={`${styles.header_info} ${styles.results_header_size}`}>
                                    <div style={{ width: '20px', height: '20px' }}>
                                        <OkIcon />
                                    </div>
                                    <p className={`${styles.info_text} fs-italic`}>{t('results.header.energy.info')}</p>
                                </div>
                            </div>
                            <div className={styles.results_footer_icon}>
                                <WellbeingHand />
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Footer />
        </>
    );
};
